if (!Framework7) {
    alert('Framework7 missing!');
}

dmx.f7 = {
    events: null,
    init: function(rootEl, params) {
        var events = dmx.f7.events || new Framework7.Events();
        var f7Params = Object.assign({}, params, {
            root: rootEl
        });

        if (window.routes && window.routes.length && !f7Params.routes) {
            f7Params.routes = routes;
        }

        var instance = new Framework7(f7Params);

        if (instance.initialized) {
            events.emit('ready', instance);
        } else {
            instance.once('init', function() {
                events.emit('ready', instance);
            });
        }

        return instance;
    },
    ready: function(callback) {
        if (!callback) return;
        if (Framework7.instance) {
            callback(Framework7.instance);
        } else {
            dmx.f7.events.once('ready', callback);
        }
    }
}

dmx.config.mapping['.view'] = 'f7-view';
dmx.config.mapping['.page'] = 'f7-page';
dmx.config.mapping['.popup'] = 'f7-popup';
dmx.config.mapping['.login-screen'] = 'f7-login-screen';
dmx.config.mapping['.actions-modal'] = 'f7-actions-modal';
dmx.config.mapping['.progressbar'] = 'f7-progressbar';
dmx.config.mapping['.toggle:not(.toggle-init)'] = 'f7-toggle';