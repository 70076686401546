dmx.Component('f7-progressbar', {

    initialData: {
        progress: 0
    },

    attributes: {
        'progress': {
            type: Number,
            default: null
        },

        'color': {
            type: String,
            default: null
        },

        'hidden': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        set: function(progress, duration) {
            Framework7.instance.progressbar.set(this.$node, progress, duration);
        },

        show: function() {
            this.props.hidden = false;
            this.update({});
        },

        hide: function() {
            this.props.hidden = true;
            this.update({});
        }
    },

    render: function (node) {
        var self = this;
        dmx.f7.ready(function(f7) {
            self.update({});
        });
    },

    update: function(props) {
        if (Framework7.instance && !dmx.equal(props, this.props)) {
            if (this.props.hidden) {
                Framework7.instance.progressbar.hide(this.$node);
            } else {
                Framework7.instance.progressbar.show(this.$node, this.props.progress, this.props.color);
            }
        }
    }

});