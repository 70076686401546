dmx.Component('f7-area-chart', {

    attributes: {
        'datasets': {
            type: Array,
            default: []
        },

        'line-chart': {
            type: Boolean,
            default: false
        },

        'axis': {
            type: Boolean,
            default: false
        },

        'axis-labels': {
            type: Array,
            default: []
        },

        'tooltip': {
            type: Boolean,
            default: false
        },

        'legend': {
            type: Boolean,
            default: false
        },

        'toggle-datasets': {
            type: Boolean,
            default: false
        },

        'max-axis-labels': {
            type: Number,
            default: 8
        }
    },

    render: function (node) {
        var self = this;
        dmx.f7.ready(function(f7) {
            self.component = f7.areaChart.create({
                el: node,
                datasets: self.props['datasets'],
                lineChart: self.props['line-chart'],
                axis: self.props['axis'],
                axisLabels: self.props['axis-labels'],
                tooltip: self.props['tooltip'],
                legend: self.props['legend'],
                toggleDatasets: self.props['toggle-datasets'],
                maxAxisLabels: self.props['max-axis-labels']
            });
        });
    },

    update: function(props) {
        if (!dmx.equal(props, this.props)) {
            this.component.update({
                datasets: self.props['datasets'],
                lineChart: self.props['line-chart'],
                axis: self.props['axis'],
                axisLabels: self.props['axis-labels'],
                tooltip: self.props['tooltip'],
                legend: self.props['legend'],
                toggleDatasets: self.props['toggle-datasets'],
                maxAxisLabels: self.props['max-axis-labels']
            });
        }
    },

    destroy: function() {
        if (this.component) {
            this.component.destroy();
            this.component = undefined;
        }
    }

});