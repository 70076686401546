dmx.Component('f7-login-screen', {

    initialData: {
        opened: false
    },

    attributes: {
        'opened': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        open: function(animate) {
            if (!this.f7LoginScreen) return;
            this.f7LoginScreen.open(animate);
        },

        close: function(animate) {
            if (!this.f7LoginScreen) return;
            this.f7LoginScreen.close(animate);
        }
    },

    events: {
        open: Event,
        opened: Event,
        close: Event,
        closed: Event
    },

    render: function (node) {
        var loginScreenParams = {
            el: node,
            on: {
                open: this.dispatchEvent.bind(this, 'open'),
                opened: this.dispatchEvent.bind(this, 'opened'),
                close: this.dispatchEvent.bind(this, 'close'),
                closed: this.dispatchEvent.bind(this, 'closed')
            }
        };

        var self = this;
        dmx.f7.ready(function(f7) {
            self.f7LoginScreen = f7.loginScreen.create(loginScreenParams);
            self.f7LoginScreen.on('opened', function() { self.set('opened', true); });
            self.f7LoginScreen.on('closed', function() { self.set('opened', false); });
            if (self.props.opened) {
                self.f7LoginScreen.open(false);
            }
        });

        this.$parse();
    },

    update: function(props) {
        if (props.opened != this.props.opened) {
            if (this.props.opened) {
                this.f7LoginScreen.open();
            } else {
                this.f7LoginScreen.close();
            }
        }

        this.set('opened', this.f7LoginScreen.opened);
    },

    destroy: function() {
        if (this.f7LoginScreen) {
            this.f7LoginScreen.destroy();
            this.f7LoginScreen = undefined;
        }
    }

});