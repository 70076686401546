dmx.Component('f7-toggle', {

    initialData: {
        checked: false
    },

    attributes: {
        'checked': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        toggle: function() {
            this.component.toggle();
            this.update({});
        }
    },

    events: {
        change: Event
    },

    render: function (node) {
        var self = this;
        dmx.f7.ready(function(f7) {
            self.component = f7.toggle.create({el: node});
            self.component.on('change', function() { self.update({}) });

            if(self.component.checked != self.props.checked) {
                self.component.toggle();
            }

            self.set('checked', self.component.checked);
        });
        this.$parse();
    },

    update: function(props) {
        if(this.component.checked != this.props.checked) {
            this.component.toggle();
        }

        this.set('checked', this.component.checked);
    },

    destroy: function(props) {
        if (this.component) {
            Framework7.instance.toggle.destroy(this.component.el);
            this.component = undefined;
        }
    }

});