dmx.Component('f7-actions-modal', {

    initialData: {
        opened: false
    },

    attributes: {
        'close-by-outside-click': {
            type: Boolean,
            default: false
        },

        'close-on-escape': {
            type: Boolean,
            default: false
        },

        'opened': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        open: function(animate) {
            if (!this.component) return;
            this.component.open(animate);
        },

        close: function(animate) {
            if (!this.component) return;
            this.component.close(animate);
        }
    },

    events: {
        open: Event,
        opened: Event,
        close: Event,
        closed: Event
    },

    render: function (node) {
        var params = {
            el: node,
            on: {
                open: this.dispatchEvent.bind(this, 'open'),
                opened: this.dispatchEvent.bind(this, 'opened'),
                close: this.dispatchEvent.bind(this, 'close'),
                closed: this.dispatchEvent.bind(this, 'closed')
            },
            closeByOutsideClick: this.props['close-by-outside-click'],
            closeOnEscape: this.props['close-on-escape']
        };

        var self = this;
        dmx.f7.ready(function(f7) {
            self.component = f7.actions.create(params);
            self.component.on('opened', function() { self.set('opened', true); });
            self.component.on('closed', function() { self.set('opened', false); });
            if (self.props.opened) {
                self.component.open(false);
            }
        });

        this.$parse();
    },

    update: function(props) {
        if (props.opened != this.props.opened) {
            if (this.props.opened) {
                this.component.open();
            } else {
                this.component.close();
            }
        }

        this.set('opened', this.component.opened);
    },

    destroy: function() {
        if (this.component) {
            this.component.destroy();
            this.component = undefined;
        }
    }

});