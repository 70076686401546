dmx.Component('f7-messages', {

    attributes: {
        'new-messages-first': {
            type: Boolean,
            default: false
        },

        'messages': {
            type: Array,
            default: []
        }
    },

    methods: {
        showTyping: function(message) {
            if (!this.component) return;
            this.component.showTyping(message);
        },

        hideTyping: function() {
            if (!this.component) return;
            this.component.hideTyping();
        },

        addMessage: function(message, method, animate) {
            if (!this.component) return;
            this.component.addMessage(message, method, animate);
        },

        addMessages: function(messages, method, animate) {
            if (!this.component) return;
            this.component.addMessages(messages, method, animate);
        },

        removeMessage: function(message) {
            if (!this.component) return;
            this.component.removeMessage(message);
        },

        removeMessages: function(messages) {
            if (!this.component) return;
            this.component.removeMessages(messages);
        },

        scroll: function(duration, position) {
            if (!this.component) return;
            this.component.scroll(duration, position);
        },

        renderMessages: function() {
            if (!this.component) return;
            this.component.renderMessages();
        },

        layout: function() {
            if (!this.component) return;
            this.component.layout();
        },

        clear: function() {
            if (!this.component) return;
            this.component.clear();
        }
    },

    getProps: function() {
        return Object.entries(this.props).reduce(function(o, entry) {
            o[entry[0].replace(/\-(.)?/g, function(m, c) { return c.toUpperCase() })] = entry[1];
            return o;
        }, {});
    },

    render: function (node) {
        var self = this;
        dmx.f7.ready(function(f7) {
            self.component = f7.messages.create(Object.assign({
                el: node
            }, self.getProps()));
        });
        this.$parse();
    },

    update: function(props) {
        if (Framework7.instance && !dmx.equal(props, this.props)) {
            this.component.destroy();
            this.component = Framework7.instance.messages.create(Object.assign({
                el: this.$node
            }, this.getProps()))
        }
    },

    destroy: function() {
        if (this.component) {
            this.component.destroy();
            this.component = undefined;
        }
    }

});