dmx.Component('f7-photo-browser', {

    initialData: {
        activeIndex: 0,
        exposed: false,
        opened: false
    },

    attributes: {
        'photos': {
            type: Array,
            default: []
        },

        'exposition': {
            type: Boolean,
            default: true
        },

        'exposition-hide-captions': {
            type: Boolean,
            default: false
        },

        'swipe-to-close': {
            type: Boolean,
            default: true
        },

        'popup-push': {
            type: Boolean,
            default: false
        },

        'routable-modals': {
            type: Boolean,
            default: false
        },

        'url': {
            type: String,
            default: 'photos/'
        },

        'type': {
            type: String,
            default: 'standalone'
        },

        'theme': {
            type: String,
            default: 'light'
        },

        'captions-theme': {
            type: String
        },

        'navbar': {
            type: Boolean,
            default: true
        },

        'toolbar': {
            type: Boolean,
            default: true
        },

        'page-back-link-text': {
            type: String,
            default: 'Back'
        },

        'page-close-link-text': {
            type: String,
            default: 'Close'
        },

        'navbar-show-count': {
            type: Boolean,
            default: undefined
        },

        'navbar-of-text': {
            type: String,
            default: 'of'
        },

        'icons-color': {
            type: String
        }
    },

    methods: {
        open: function(index) {
            if (!this.component) return;
            this.component.open(index);
        },

        close: function() {
            if (!this.component) return;
            this.component.close();
        },

        expositionToggle: function() {
            if (!this.component) return;
            this.component.expositionToggle();
        },

        expositionEnable: function() {
            if (!this.component) return;
            this.component.expositionEnable();
        },

        expositionDisable: function() {
            if (!this.component) return;
            this.component.expositionDisable();
        }
    },

    events: {
        tap: Event,
        click: Event,
        doubleTap: Event,
        doubleClick: Event,
        slideChange: Event,
        lazyImageLoad: Event,
        lazyImageReady: Event,
        open: Event,
        opened: Event,
        close: Event,
        closed: Event
    },

    getProps: function() {
        return Object.entries(this.props).reduce(function(o, entry) {
            o[entry[0].replace(/\-(.)?/g, function(m, c) { return c.toUpperCase() })] = entry[1];
            return o;
        }, {});
    },

    render: function (node) {
        var self = this;
        dmx.f7.ready(function(f7) {
            self.component = f7.photoBrowser.create(this.getProps());
            self.component.on('opened', function() { self.set('opened', true) });
            self.component.on('closed', function() { self.set('opened', false) });
            self.component.on('tap', self.dispatchEvent.bind(self, 'tap'))
            self.component.on('click', self.dispatchEvent.bind(self, 'click'))
            self.component.on('doubleTap', self.dispatchEvent.bind(self, 'doubleTap'))
            self.component.on('doubleClick', self.dispatchEvent.bind(self, 'doubleClick'))
            self.component.on('slideChange', self.dispatchEvent.bind(self, 'slideChange'))
            self.component.on('lazyImageLoad', self.dispatchEvent.bind(self, 'lazyImageLoad'))
            self.component.on('lazyImageReady', self.dispatchEvent.bind(self, 'lazyImageReady'))
            self.component.on('open', self.dispatchEvent.bind(self, 'open'))
            self.component.on('opened', self.dispatchEvent.bind(self, 'opened'))
            self.component.on('close', self.dispatchEvent.bind(self, 'close'))
            self.component.on('closed', self.dispatchEvent.bind(self, 'closed'))
        });
        this.$parse();
    },

    update: function(props) {
        if (Framework7.instance && !dmx.equal(props, this.props)) {
            var self = this;
            this.component.destroy();
            this.component = Framework7.instance.photoBrowser.create(this.getProps());
            this.component.on('opened', function() { self.set('opened', true) });
            this.component.on('closed', function() { self.set('opened', false) });
            this.component.on('opened', function() { self.set('opened', true) });
            this.component.on('closed', function() { self.set('opened', false) });
            this.component.on('tap', this.dispatchEvent.bind(this, 'tap'))
            this.component.on('click', this.dispatchEvent.bind(this, 'click'))
            this.component.on('doubleTap', this.dispatchEvent.bind(this, 'doubleTap'))
            this.component.on('doubleClick', this.dispatchEvent.bind(this, 'doubleClick'))
            this.component.on('slideChange', this.dispatchEvent.bind(this, 'slideChange'))
            this.component.on('lazyImageLoad', this.dispatchEvent.bind(this, 'lazyImageLoad'))
            this.component.on('lazyImageReady', this.dispatchEvent.bind(this, 'lazyImageReady'))
            this.component.on('open', this.dispatchEvent.bind(this, 'open'))
            this.component.on('opened', this.dispatchEvent.bind(this, 'opened'))
            this.component.on('close', this.dispatchEvent.bind(this, 'close'))
            this.component.on('closed', this.dispatchEvent.bind(this, 'closed'))
        }

        this.set('activeIndex', this.component.activeIndex);
        this.set('exposed', this.component.exposed);
        this.set('opened', this.component.opened);
    },

    destroy: function() {
        if (this.component) {
            this.component.destroy();
            this.component = undefined;
        }
    }

});