dmx.Component('f7-popup', {

    initialData: {
        opened: false
    },

    attributes: {
        'close-on-escape': {
            type: Boolean,
            default: false
        },

        'swipe-to-close': {
            type: Boolean,
            default: false
        },

        'opened': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        open: function(animate) {
            if (!this.f7Popup) return;
            this.f7Popup.open(animate);
        },

        close: function(animate) {
            if (!this.f7Popup) return;
            this.f7Popup.close(animate);
        }
    },

    events: {
        open: Event,
        opened: Event,
        close: Event,
        closed: Event
    },

    render: function (node) {
        var popupParams = {
            el: node,
            on: {
                open: this.dispatchEvent.bind(this, 'open'),
                opened: this.dispatchEvent.bind(this, 'opened'),
                close: this.dispatchEvent.bind(this, 'close'),
                closed: this.dispatchEvent.bind(this, 'closed')
            },
            closeOnEscape: this.props['close-on-escape'],
            swipeToClose: this.props['swipe-to-close']
        };

        var self = this;
        dmx.f7.ready(function(f7) {
            self.f7Popup = f7.popup.create(popupParams);
            self.f7Popup.on('opened', function() { self.set('opened', true); });
            self.f7Popup.on('closed', function() { self.set('opened', false); });
            if (self.props.opened) {
                self.f7Popup.open(false);
            }
        });

        this.$parse();
    },

    update: function(props) {
        if (props.opened != this.props.opened) {
            if (this.props.opened) {
                this.f7Popup.open();
            } else {
                this.f7Popup.close();
            }
        }

        this.set('opened', this.f7Popup.opened);
    },

    destroy: function() {
        if (this.f7Popup) {
            this.f7Popup.destroy();
            this.f7Popup = undefined;
        }
    }

});