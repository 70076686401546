dmx.Component('f7-notification', {

    attributes: {
        'icon': {
            type: String
        },

        'title': {
            type: String
        },

        'title-right-text': {
            type: String
        },

        'subtitle': {
            type: String
        },

        'text': {
            type: String
        },

        'close-button': {
            type: Boolean,
            default: false
        },

        'close-timeout': {
            type: Number
        },

        'close-on-click': {
            type: Boolean,
            default: false
        },

        'swipe-to-close': {
            type: Boolean,
            default: true
        },

        'css-class': {
            type: String
        },

        'opened': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        open: function(animate) {
            if (!this.component) return;
            this.component.open(animate);
        },

        close: function(animate) {
            if (!this.component) return;
            this.component.close(animate);
        }
    },

    events: {
        open: Event,
        opened: Event,
        close: Event,
        closed: Event
    },

    getProps: function() {
        return Object.entries(this.props).reduce(function(o, entry) {
            o[entry[0].replace(/\-(.)?/g, function(m, c) { return c.toUpperCase() })] = entry[1];
            return o;
        }, {});
    },

    render: function (node) {
        var self = this;
        dmx.f7.ready(function(f7) {
            self.component = f7.notification.create(Object.assign({
                on: {
                    open: this.dispatchEvent.bind(this, 'open'),
                    opened: this.dispatchEvent.bind(this, 'opened'),
                    close: this.dispatchEvent.bind(this, 'close'),
                    closed: this.dispatchEvent.bind(this, 'closed')
                }
            }, this.getProps()));

            self.component.on('opened', function() { self.set('opened', true); });
            self.component.on('closed', function() { self.set('opened', false); });
            
            if (self.props.opened) {
                self.component.open(false);
            }
        });
        this.$parse();
    },

    update: function(props) {
        if (Framework7.instance && !dmx.equal(props, this.props)) {
            Framework7.instance.notification.destroy(this.component.el);

            this.component = Framework7.instance.notification.create(Object.assign({
                on: {
                    open: this.dispatchEvent.bind(this, 'open'),
                    opened: this.dispatchEvent.bind(this, 'opened'),
                    close: this.dispatchEvent.bind(this, 'close'),
                    closed: this.dispatchEvent.bind(this, 'closed')
                }
            }, this.getProps()));

            var self = this;
            this.component.on('opened', function() { self.set('opened', true); });
            this.component.on('closed', function() { self.set('opened', false); });

            if (this.props.opened) {
                this.component.open(false);
            }
        }

        this.set('opened', this.component.opened);
    },

    destroy: function() {
        if (this.component) {
            Framework7.instance.notification.destroy(this.component.el);
            this.component = undefined;
        }
    }

});