dmx.Component('f7-gauge', {

    attributes: {
        'type': {
            type: String,
            default: 'circle'
        },

        'value': {
            type: Number,
            default: 0
        },

        'size': {
            type: Number,
            default: 200
        },

        'bg-color': {
            type: String,
            default: 'transparent'
        },

        'border-bg-color': {
            type: String,
            default: '#eeeeee'
        },

        'border-color': {
            type: String,
            default: '#000000'
        },

        'border-width': {
            type: String,
            default: '10'
        },

        'value-text': {
            type: String,
            default: null
        },

        'value-text-color': {
            type: String,
            default: '#000000'
        },

        'value-font-size': {
            type: String,
            default: '31'
        },

        'value-font-weight': {
            type: String,
            default: '500'
        },

        'label-text': {
            type: String,
            default: null
        },
        
        'label-text-color': {
            type: String,
            default: '#888888'
        },

        'label-font-size': {
            type: String,
            default: '14'
        },

        'label-font-weight': {
            type: String,
            default: '400'
        }
    },

    getProps: function() {
        return Object.entries(this.props).reduce(function(o, entry) {
            o[entry[0].replace(/\-(.)?/g, function(m, c) { return c.toUpperCase() })] = entry[1];
            return o;
        }, {});
    },

    render: function (node) {
        var self = this;
        dmx.f7.ready(function(f7) {
            self.component = f7.gauge.create(Object.assign({
                el: node
            }, this.getProps()));
        });
    },

    update: function(props) {
        if (!dmx.equal(props, this.props)) {
            this.component.update(this.getProps());
        }
    },

    destroy: function() {
        if (this.component) {
            this.component.destroy();
            this.component = undefined;
        }
    }

});