dmx.Component('f7-pie-chart', {

    attributes: {
        'datasets': {
            type: Array,
            default: []
        },

        'size': {
            type: Number,
            default: 320
        },

        'tooltip': {
            type: Boolean,
            default: false
        }
    },

    getProps: function() {
        return Object.entries(this.props).reduce(function(o, entry) {
            o[entry[0].replace(/\-(.)?/g, function(m, c) { return c.toUpperCase() })] = entry[1];
            return o;
        }, {});
    },

    render: function (node) {
        var self = this;
        dmx.f7.ready(function(f7) {
            self.component = f7.pieChart.create(Object.assign({
                el: node
            }, self.getProps()));
        });
    },

    update: function(props) {
        if (!dmx.equal(props, this.props)) {
            this.component.update(this.getProps());
        }
    },

    destroy: function() {
        if (this.component) {
            this.component.destroy();
            this.component = undefined;
        }
    }

});